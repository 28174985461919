<template>
    <section class="op-ver-cotizacion">
        <tabla-general 
        alturaTabla="calc(100vh - 350px)" 
        layoutServidor="prev, pager, next"
        :mostrarBuscador="false" 
        :usarPaginacion="false"
        :data="cotizaciones" 
        >
            <el-table-column label="Ref." prop="referencia" width="100">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">{{ scope.row.referencia }}</p>
                </template>
            </el-table-column>
            <el-table-column label="Nombre" prop="nombre" min-width="300">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos f-600">
                        {{ scope.row.nombre }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Tipo" prop="tipo" width="100">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.tipo }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Total de unidades" prop="total_unidades" min-width="80" align="center" header-align="center">
                <template slot-scope="scope">
                    {{ scope.row.total_unidades || '0' }}
                </template>
            </el-table-column>
            <el-table-column label="Proveedor favorito material" prop="proveedor_favorito_material" min-width="100" header-align="center">
                <template slot-scope="scope">
                    {{ scope.row.proveedor_favorito_material || '-' }}
                </template>
            </el-table-column>
            <el-table-column label="Proveedor favorito categoría" prop="proveedor_favorito_categoria" min-width="100" header-align="center">
                <template slot-scope="scope">
                    {{ scope.row.proveedor_favorito_categoria || '-' }}
                </template>
            </el-table-column>
            <el-table-column label="Valor ultima cotización" prop="valor_ultima_cotizacion" min-width="100" align="center" header-align="center">
                <template slot-scope="scope">
                    {{ formatoMoneda(scope.row.valor_ultima_cotizacion) }}
                </template>
            </el-table-column>
        </tabla-general>

        <div class="d-middle-center">
            <Pagination :pagination="pagination" layout="prev, pager, next" @paginate="paginateCotizations" />
        </div>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    components: { 
    },
    data(){
        return{
            options: [
                {
                    value: 'Option1',
                    label: 'Option1'
                },
            ],
        }
    },
    computed: {
        ...mapGetters({
            cotizaciones: 'oportunidades/ver/cotizaciones/cotizaciones',
        }),
        pagination: {
            get() {
                return this.$store.getters['oportunidades/ver/cotizaciones/pagination']
            },
            set(val){
                this.$store.commit('oportunidades/ver/cotizaciones/set_pagination', val)
            }
        }
    },
    methods:{
        ...mapActions({
            Action_get_cotizaciones: 'oportunidades/ver/cotizaciones/Action_get_cotizaciones'
        }),
        verCotizaciones(){
            this.$router.push({name: 'oportunidades.ver.cotizaciones.ver'})
        },
        paginateCotizations(page = 1){
            this.pagination.current_page = page
            this.Action_get_cotizaciones(this.$route.params.id)
        }
    }
}
</script>

<style lang="scss" scoped>

</style>